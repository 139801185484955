<template>
	<div class="login">
		<van-form @submit="onSubmit" class="login-form">
			<div style="text-align: center;padding: 30px 0;">
				<van-image fit="cover" round src="https://img01.yzcdn.cn/vant/cat.jpeg" class="login-image" />
			</div>
			<van-field v-model.trim="form.username" label="账号" placeholder="请输入账号" clearable
				:rules="[{ required: true, message: '请填写账号' }]" />
			<van-field v-model.trim="form.password" center clearable label="密码" :maxlength="6" type="password"
				placeholder="请输入密码" :rules="[{ required: true, message: '请填写密码' }]" />
			<div style="margin: 20px;">
				<button @click="submit"
					style="width: 100%; height: 37px;font-size: 16px;background: #FC6228;border: none;color: #FFFFFF;border-radius: 18px;">
					提交
				</button>
			</div>
		</van-form>
	</div>
</template>
<script>
	import {
		login
	} from '@/api/login'

	export default {
		data() {
			return {
				form: {
					username: '18800000001',
					password: 123456,
				}
			}
		},
		mounted() {},
		methods: {
			submit() {
				login(this.form).then((res) => {
					console.log(res)
					this.$store.commit('SET_TOKEN', res.data.token)
					this.$store.commit('SET_IS_LOGIN', true)
					window.localStorage.setItem('token', res.data.token);
					if (this.$route.query.redirect) window.location.href = this.$route.query.redirect
					else window.location.href = '/'
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.login {
		min-height: calc(100vh);
		background: white;

		.login-form {
			background: white;
			padding: 0 20px;
		}

		.login-image {
			width: 80px;
			height: 80px;
		}
	}
</style>
